import React from 'react'
import { graphql } from 'gatsby'
// import { Link, graphql } from 'gatsby'
// import Masonry from 'react-masonry-component'
import Img from 'gatsby-image'
import Layout from "../components/layout"

const IndexPage = ({ data }) => (
  <Layout>
    <div className="hero">
      <div className="exclaim" />
      <h1 className="title-1 hero-title">{data.datoCmsHome.heroTitle}</h1>
      <div
        className="hero-text"
        dangerouslySetInnerHTML={{
          __html: data.datoCmsHome.heroBodyNode.childMarkdownRemark.html
        }}
      />
      <div className="xando" />

      <div
        className="hero-info-left"
        dangerouslySetInnerHTML={{
          __html: data.datoCmsHome.heroLeftInfoNode.childMarkdownRemark.html
        }}
      />
      <div
        className="hero-info-right"
        dangerouslySetInnerHTML={{
          __html: data.datoCmsHome.heroRightInfoNode.childMarkdownRemark.html
        }}
      />

      <button
        className="hero-button button-1"
        dangerouslySetInnerHTML={{
          __html: data.datoCmsHome.heroButtonNode.childMarkdownRemark.html
        }}
      />
    </div>

    <div
      className="assembly"
      dangerouslySetInnerHTML={{
        __html: data.datoCmsHome.assemblyNode.childMarkdownRemark.html
      }}
    />

    <div className="products">
      {
        data.datoCmsHome.product.map((block) => (
          <span key={block.id}>
            {
              <h2 className="title-2 product-title">
                {block.productTitle}
              </h2>
            }
            {
              <div dangerouslySetInnerHTML={{
                __html: block.productBodyNode.childMarkdownRemark.html,
              }}>
              </div>
            }
            {
              <Img className="product-image" fluid={block.productImage.fluid} alt={block.productImage.alt} />
            }
          </span>
        ))
      }
    </div>

    <div className="information">
      <div className="bar" />
      <h2 className="title-1 info-title">{data.datoCmsHome.infoTitle}</h2>
      <div
        className="info-top-text"
        dangerouslySetInnerHTML={{
          __html: data.datoCmsHome.infoBodyNode.childMarkdownRemark.html
        }}
      />
      <div
        className="info-text-block"
        dangerouslySetInnerHTML={{
          __html: data.datoCmsHome.infoBlockNode.childMarkdownRemark.html
        }}
      />
      <div
        className="info-text-center"
        dangerouslySetInnerHTML={{
          __html: data.datoCmsHome.infoCenterNode.childMarkdownRemark.html
        }}
      />
      <div
        id="single-and-double-seal-systems"
        className="info-text-right"
        dangerouslySetInnerHTML={{
          __html: data.datoCmsHome.infoRightNode.childMarkdownRemark.html
        }}
      />
    </div>

    <div className="contact">
      <h2 className="title-3 contact-title">{data.datoCmsHome.contactTitle}</h2>
      <div
        className="contact-body"
        dangerouslySetInnerHTML={{
          __html: data.datoCmsHome.contactBodyNode.childMarkdownRemark.html
        }}
      />
      <div
        className="contact-button"
        dangerouslySetInnerHTML={{
          __html: data.datoCmsHome.contactButtonNode.childMarkdownRemark.html
        }}
      />
    </div>

  </Layout>
)

export default IndexPage

export const query = graphql`
  query IndexQuery {
    datoCmsHome {

      heroTitle

      heroBodyNode {
        childMarkdownRemark {
          html
        }
      }

      heroButtonNode {
        childMarkdownRemark {
          html
        }
      }

      assemblyNode {
        childMarkdownRemark {
          html
        }
      }

      heroLeftInfoNode {
        childMarkdownRemark {
          html
        }
      }

      heroRightInfoNode {
        childMarkdownRemark {
          html
        }
      }

      product {
        ...on DatoCmsProductItem {
          model { apiKey }
          productTitle
          productBody
          productBodyNode {
            childMarkdownRemark {
              html
            }
          }
          productImage {
            fluid(imgixParams: { auto: "compress" }) {
              ...GatsbyDatoCmsSizes
              src
            }
            alt
          }
        }
      }

      infoTitle

      infoBodyNode {
        childMarkdownRemark {
          html
        }
      }

      infoBlockNode {
        childMarkdownRemark {
          html
        }
      }

      infoCenterNode {
        childMarkdownRemark {
          html
        }
      }

      infoRightNode {
        childMarkdownRemark {
          html
        }
      }

      contactTitle

      contactBodyNode {
        childMarkdownRemark {
          html
        }
      }

      contactButtonNode {
        childMarkdownRemark {
          html
        }
      }

    }

    allDatoCmsWork(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          coverImage {
            fluid(maxWidth: 450, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`
